<template>
  <div>
    <v-card class="pa-0 tab-item-card" flat>
      <v-img class="section-title-bg-img" src="../../assets/deposit.jpg"></v-img>
      <div class="text-center py-12">
        <span class="text-h3 text-sm-h2 text-md-h1">Special conditions</span>
      </div>
      <v-container class="px-5 py-5 py-sm-12">
        <p>Any clauses added are negotiated between the landlord or their agent and the tenant. They have not been vetted or approved by Propertymark.</p>
        <v-card class="mt-6 blue darken-4 pa-3" outlined flat>
          <v-card-title class="white">
            <span class="text-h5">State any requests and conditions here</span>
          </v-card-title>
          <v-card-text class="white pt-3">
            <v-card outlined flat class="pa-3">
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    outlined
                    type="number"
                    placeholder="e.g 8.1"
                    name="name"
                    label="Clause number"
                    id="clause-number"
                  ></v-text-field>
                </v-col>
                <v-col class="mt-n8" cols="12">
                  <v-textarea
                    auto-grow
                    rows="3"
                    outlined
                    placeholder="e.g.: The landlord agrees to emptying the garage."
                  ></v-textarea>
                </v-col>
                <v-col class="mt-n8 d-flex justify-end" cols="12">
                  <v-btn color="primary" width="200">
                    <span class="secondary--text custom-transform-class text-none">Add clause</span>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <span class="text-body-1 font-weight-bold">Added clauses:</span>
                  <v-divider class="my-3"></v-divider>
                  <p>8.1 The landlord agrees to 2 cats</p>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
        </v-card>
      </v-container>
      <div class="blue darken-4 text-center py-12">
        <v-container class="px-5 py-8">
          <p class="white--text text-h5 text-sm-h4">Any special conditions added are subject to confirmation with the landlord and are subject to checking if a term or condition of the tenancy agreement is giving either party less than their statutory rights</p>
        </v-container>
      </div>
    </v-card>
    <MainPagination 
      previousPage="/review/offer-info"
      nextPage="/review/sign"
    />
  </div>
</template>

<script>
  export default {
    name: 'agreement-special-conditions'
}
</script>

<style>

</style>